export const getId = (arr,key) => {
  return arr.map(item => {
    return item[key]
  })
}

export const deWeightThree = (arr) => {
  let map = new Map();
  for (let item of arr) {
      if (!map.has(item.text)) {
          map.set(item.text, item)
      }
  }
  return [...map.values()]
}

export const findObj = (arr,obj) => {
  for(let i = 0; i < arr.length; i++) {
      if(JSON.stringify(arr[i]) == JSON.stringify(obj)) {
          return i
      }
  }
  return -1
}

export const rulesGenerator = (msg) => {
  return [{ required: true, message: msg, trigger: ["blur", "change"] }];
};